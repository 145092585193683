import styled from "styled-components";
import { checkBootstrapSizeBreak } from "../../../utils/device/checkDevice";
const breakPoint = checkBootstrapSizeBreak();

export const SecctionTitleContainer = styled.div`
  height: 80%;
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${() => (breakPoint <= 2 ? "1.2rem" : "1.6rem")};
  /* padding: 8px 20px; */
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  /* -webkit-box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%); */
  /* -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75); */
  /* box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%); */
  /* border: 1px solid #00a79d; */
  /* border-radius: 5px 20px; */
`;

export const SettingButton = styled.button`
  border: 0px;
  background: transparent;
  color: ${({ $active, theme }) =>
    $active ? theme.links.settings.active : theme.links.settings.default};
  text-decoration: ${({ $active }) => ($active ? "underline" : "none")};
  padding: 10px 10px 10px 10px;
  font-size: 1.2rem;
  font-weight: 500;

  &:hover {
    color: ${({ $active, theme }) =>
      $active ? theme.links.settings.active : theme.links.settings.hover};
  }
`;

export const BodyContainer = styled.div`
  min-height: calc(100vh - 0px) !important;
  background-color: ${({ theme }) =>
    theme ? theme.body.backgroundColor : "#fff"};
`;

export const PageContent = styled.div`
  /* margin-right: -15px;
  margin-left: -15px; */
`;

export const SectionBanner = styled.div`
  background: ${({ bannerName }) =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/${bannerName}')`};
  background-position: ${({ bannerPosition }) =>
    `bottom ${bannerPosition}% center`};
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-attachment: fixed; */
  height: 20vh;
  background-blend-mode: multiply;
  margin-right: -15px;
  margin-left: -15px;
`;

export const BannerText = styled.div`
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
