import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import PATHS from "../../../../../../constants/paths";
import { IconStyled } from "./styled";
import { AuthContainer } from "../../../../../security/auth/auth.container";
import { useNavigate } from "react-router-dom";

const UserMenu = () => {
  const { t } = useTranslation();
  const auth = AuthContainer.useContainer();
  const navigate = useNavigate();

  const onClick = async (key) => {
    switch (key) {
      case "closeSession":
        auth.logout();
        openCenteredPopup(
          `https://login.microsoftonline.com/99dbb24d-723f-44f0-b082-24b8ce4784d4/oauth2/logout`,
          `logout azureAd`,
          150,
          150
        );
        break;
      case "setting":
        navigate(PATHS.SETTING_PROFILE);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Menu
        menuButton={
          <MenuButton className="userMenu" title="Menu">
            <i className="fa-solid fa-user-large"></i>
          </MenuButton>
        }
        direction="bottom"
        align="end"
        position="anchor"
        viewScroll="auto"
        arrow={true}
        // gap={12}
      >
        <MenuItem
          value="setting"
          onClick={(e) => {
            onClick(e.value);
          }}
        >
          <IconStyled className="fa-solid fa-screwdriver-wrench" />
          {t("menu.setting")}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          value="closeSession"
          onClick={(e) => {
            onClick(e.value);
          }}
        >
          <IconStyled className="fa-solid fa-right-from-bracket" />
          {t("close_session")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;

export const openCenteredPopup = (url, title, w, h) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=no,
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      resizable=no,
      copyhistory=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  newWindow?.focus();

  setTimeout(() => {
    newWindow.close();
  }, 5000);
};
