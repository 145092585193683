import { createAction } from "redux-actions";
import {
  LOGIN_SUCCESS,
  CLEAN_AUTH,
  LOGIN,
  REFRESH_TOKEN,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
} from "../../constants/index";
import { apiPost, apiGet, apiPut } from "../../api/index";
import {
  urlAuthLogin,
  urlRefreshToken,
  urlLogout,
  urlAuthLoginAzure,
  urlAuthChangePass,
} from "../../api/urls";

export const loginSucess = createAction(LOGIN_SUCCESS, (data) => data);
export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const setAuthUser = createAction(SET_AUTH_USER, (data) => data);
export const setAuthToken = createAction(SET_AUTH_TOKEN, (data) => data);
/**
 * Login User.
 */
export const loginUser = createAction(LOGIN, (obj) =>
  apiPost(urlAuthLogin, obj, undefined)()
);

/**
 * Login User.
 */
export const loginUserAzure = createAction("LOGIN_AZURE", () =>
  apiGet(urlAuthLoginAzure, undefined, undefined)()
);

/**
 * User Refresh token.
 */
export const userRefreshToken = createAction(REFRESH_TOKEN, (obj) =>
  apiGet(urlRefreshToken, undefined, undefined)()
);

/**
 * User Refresh token.
 */
export const userLogout = createAction(CLEAN_AUTH, (obj) =>
  apiGet(urlLogout, undefined, undefined)()
);

/**
 * Get auth info profile (user loged).
 */
export const updatePassword = createAction("CHANGE_PASSWORD", (obj) =>
  apiPut(urlAuthChangePass, obj, undefined, true)()
);
