import { combineReducers } from "redux";
import { reducer as modal } from "redux-modal";
// import { ignoreActions } from 'redux-ignore';

import { auth } from "./auth";
import { nav } from "./navigation";

export default combineReducers({
  modal,
  auth,
  nav,
});
