import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../commons/header";
import {
  checkBootstrapTypeBreak,
  checkBootstrapSizeBreak,
} from "../../../utils/device/checkDevice";
import {
  PageContent,
  BodyContainer,
  SettingButton,
  SectionBanner,
  BannerText,
  SecctionTitleContainer,
} from "./styled";
// import { SecctionTitleContainer } from "../../../design/styleComponents/globalComponents";
import PATHS from "../../../constants/paths";
// import Nav from "../commons/nav";
// import ShoppingCartNav from "../commons/shoppingCartNav";
// import Footer from "../commons/footer";
// import { Can } from "../../permissions/Can";
// import { USER_PERMISSIONS } from "../../permissions/rolesPermission";

const SettingsLayout = (props) => {
  const { type, showBanner, bannerName, textBanner, bannerPosition } = props;
  const breakSize = checkBootstrapSizeBreak();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goTo = (key) => {
    switch (key) {
      case "my_profile":
        navigate(PATHS.SETTING_PROFILE);
        break;
      case "security":
        navigate(PATHS.SETTING_SECURITY);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div id="sb-site">
        <BodyContainer className="container-fluid" id="page-wrapper">
          <div className="sticky-top">
            <Header
              showUserMenu={
                props.showUserMenu !== undefined
                  ? props.showUserMenu
                  : undefined
              }
            />
          </div>
          <div id="page-content-wrapper">
            {/* {!props.hiddenButtonShoppingCart && <ShoppingCartNav />} */}
            <PageContent id="page-content">
              <div className="row justify-content-center">
                <SecctionTitleContainer className="col-11 col-md-10 mt-5 mb-5">
                  {t("settings")}
                </SecctionTitleContainer>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <ul
                        className={
                          breakSize < 3 ? "nav mb-3" : "nav flex-column"
                        }
                      >
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            $active={props.active && props.active === "profile"}
                            type="button"
                            onClick={() => goTo("my_profile")}
                          >
                            {t("my_profile")}
                          </SettingButton>
                        </li>
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            $active={
                              props.active && props.active === "security"
                            }
                            type="button"
                            onClick={() => goTo("security")}
                          >
                            {t("security")}
                          </SettingButton>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-9">{props.body}</div>
                  </div>
                </div>
              </div>
            </PageContent>
          </div>
        </BodyContainer>
      </div>
    </>
  );
};

export default SettingsLayout;
