import * as Yup from "yup";

export const dataModel = {
  password: "",
  passwordConfirm: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    password: Yup.string()
      .required("forms.validations.required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
        "forms.validations.password"
      ),
    passwordConfirm: Yup.string()
      .required("forms.validations.required")
      .oneOf([Yup.ref("password")], "forms.validations.matchPassword"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
