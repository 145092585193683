// import { AuthContainer } from "../components/security/auth/auth.container";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthUserSelector } from "../store/selectors/authSelector";

const useAuth = () => {
  const user = useSelector(getAuthUserSelector);
  // const { user } = AuthContainer.useContainer();
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
