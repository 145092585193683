export const USER_PERMISSIONS = {
  STAFF: "STAFF",
  ADD_OUTPUT: "ADD_OUTPUT",
  LIST_INPUTS: "LIST_INPUTS",
  LIST_OUTPUT: "LIST_OUTPUT",
  LIST_USER_OUTPUT: "LIST_USER_OUTPUT",
  LIST_STOP_HISTORY: "LIST_STOP_HISTORY",
  EXECUTE_STOP: "EXECUTE_STOP",
  LIST_MOVE: "LIST_MOVE",
  EXPORT_MOVE: "EXPORT_MOVE",
  FILTER_MOVE: "FILTER_MOVE",
  ERROR_LIST: "ERROR_LIST",
  LIST_ITEMS: "LIST_ITEMS",
};
