import styled from "styled-components";

export const BodyContainer = styled.div`
  min-height: calc(100vh - 293px) !important;
  background-color: ${({ theme }) =>
    theme ? theme.body.backgroundColor : "#fff"};
`;

export const SideBarContainer = styled.div`
  width: 220px;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) =>
    theme ? theme.sidebar.backgroundColor : "#fff"};
  transition: 0.5s;
`;

export const OffCanvas = styled.div`
  top: 0;
  left: 0;
  width: 220px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) =>
    theme ? theme.sidebar.backgroundColor : "#fff"};
  transform: translateX(-100%);
`;

export const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #26abe1;
  color: #fff;
`;

// export const LinkTerms = styled(Link)`
//   margin-right: 20px;
//   color: #fff;
//   margin-bottom: 15px;

//   &:hover {
//     color: #fff;
//   }
// `;

export const PageContent = styled.div`
  /* margin-right: -15px;
  margin-left: -15px; */
`;

export const SectionBanner = styled.div`
  background: ${({ bannerName }) =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/${bannerName}')`};
  background-position: ${({ bannerPosition }) =>
    `bottom ${bannerPosition}% center`};
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-attachment: fixed; */
  height: 20vh;
  background-blend-mode: multiply;
  margin-right: ${({ breakSizeDevice }) =>
    breakSizeDevice < 3 ? "0px" : "-15px"};
  margin-left: ${({ breakSizeDevice }) =>
    breakSizeDevice < 3 ? "0px" : "-15px"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannerText = styled.div`
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ImgBannerCenter = styled.img`
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
`;
