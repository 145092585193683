import { store } from "../store/index";
import {
  loginUser,
  userRefreshToken,
  userLogout,
  setAuthUser,
  setAuthToken,
  loginUserAzure,
  updatePassword,
} from "../store/actions/AuthActions";

export const refreshToken = async () => {
  const result = await store.dispatch(userRefreshToken());
  if (result?.payload?.user) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return result.payload;
  }

  return null;
};

export const login = async (email, password) => {
  const result = await store.dispatch(
    loginUser({
      email,
      password,
    })
  );
  if (result?.payload?.user) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return result.payload;
  }

  return null;
};

export const loginAzure = async (email, password) => {
  await store.dispatch(loginUserAzure());
  return "OK";
};

export const logout = async () => {
  await store.dispatch(userLogout());

  return null;
};

/**
 * Update password
 */
export const changePassword = async (values) => {
  const objSend = {
    password: values.password,
  };

  const result = await store.dispatch(updatePassword(objSend));
  if (result && result.payload) {
    return "OK";
  }
  return null;
};
