import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import InputText from "../../../utils/InputText";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./changePassword.data.form";

const ChangePasswordForm = (props) => {
  const { t, handleSubmit } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="row">
            <div className="col-12 mb-4">
              <h3>
                <ins>{t("forms.security.changePass")}</ins>
              </h3>
            </div>
          </div>
          <form
            className="form-horizontal bordered-row"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12 col-sm-7 col-lg-8">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="password"
                      className="form-control"
                      labelField={t("forms.security.labels.password")}
                      name="password"
                      component={InputText}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="passwordConfirm"
                      className="form-control"
                      labelField={t("forms.security.labels.passwordConfirm")}
                      name="passwordConfirm"
                      component={InputText}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-8 text-end">
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.security.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const ChangePasswordFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel();
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ChangePasswordForm",
})(withTranslation()(ChangePasswordForm));

export default ChangePasswordFormF;
