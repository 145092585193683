import React from "react";
import UserSecurity from "../../../../components/user/security";
import Layout from "../../../../components/layouts/settingsLayout";

const SecuritySettingsPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      active="security"
      body={
        <>
          <UserSecurity />
        </>
      }
    />
  );
};

SecuritySettingsPage.propTypes = {};

export default SecuritySettingsPage;
