/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import Axios from "axios";
// import promise from "promise";
// import { show, hide } from "redux-modal";
// import { handleErrorResponse } from "../utils/errors/ErrorControl";
// import { store } from "../store";

// const showLoading = () => {
//   const currentState = store.getState();
//   if (
//     !currentState.modal.LoadingModal ||
//     (currentState.modal.LoadingModal && !currentState.modal.LoadingModal.show)
//   ) {
//     store.dispatch(show("LoadingModal", {}));
//   }
// };

// const hideLoading = () => {
//   const currentState = store.getState();
//   if (
//     currentState.modal.LoadingModal ||
//     (currentState.modal.LoadingModal &&
//       currentState.modal.LoadingModal.show === true)
//   ) {
//     store.dispatch(hide("LoadingModal", {}));
//   }
// };

// Add a request interceptor
export const axios = Axios.create({
  withCredentials: true,
});

// use request with credentials, auto save login token into browser
// axiosInstanceNormal.defaults.withCredentials = true;
