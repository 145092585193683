import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as GeneralServices from "../../../../../../services/GeneralServices";
import PropTypes from "prop-types";
import {
  LANGUAGES,
  LANGUAGE_ES,
  LANGUAGE_PT,
} from "../../../../../../constants/index";
import { useSelector } from "react-redux";
import { getLanguageSelector } from "../../../../../../store/selectors/navSelector";
import { LanguageContainer, Flag } from "./styled";

const Languages = (props) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(undefined);
  const [languages, setLanguages] = useState([]);
  const languagePreference = useSelector(getLanguageSelector);

  useEffect(() => {
    let language;
    let findLng = LANGUAGES.find((lng) => lng.code === languagePreference);
    if (!findLng) {
      language = LANGUAGE_PT.code;
    } else {
      language = findLng.code;
    }
    changeLanguage(language);
  }, [languagePreference]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    GeneralServices.setPreferredLanguage(lang);
    setSelected(lang ? lang : "pt");
    setLanguages(LANGUAGES.map((lang) => lang.code));
    // if (
    //   token &&
    //   preferendLanguage &&
    //   preferendLanguage !== this.props.i18n.language
    // ) {
    //   UserService.updatePreferendLanguage(lang);
    // }
  };

  return (
    <LanguageContainer className="dropdown">
      <button
        className="btn btn-sm dropdown-toggle d-flex align-items-center"
        type="button"
        data-toggle="dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        id="dropdownMenuButton"
      >
        <Flag countryCode={selected === "en" ? "us" : selected} svg />
        {selected
          ? t(`language.languages.${selected}`)
          : t("language.select_option")}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {languages && languages.length > 0 && (
          <>
            {languages.map((lang) => {
              return (
                <button
                  key={lang}
                  className="dropdown-item language-item"
                  onClick={() => {
                    changeLanguage(lang);
                  }}
                >
                  <Flag countryCode={lang === "en" ? "us" : lang} svg />
                  {t(`language.languages.${lang}`)}
                </button>
              );
            })}
          </>
        )}
      </div>
    </LanguageContainer>
  );
  // }
};

Languages.propTypes = {
  token: PropTypes.string,
};

export default Languages;
