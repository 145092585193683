import styled from "styled-components";

export const UserIcon = styled.i`
  color: #fff;
  margin-right: 10px;
  font-size: 1.5rem;
`;

export const UserContainerName = styled.div`
  color: #fff;
  margin-right: 10px;
  display: flex;
`;

export const HeaderContainer = styled.div`
  height: 90px;
  /* background-image: url("assets/themes/deprado/images/fondo-menu.png"); */
  background: ${({ theme }) => theme.header.backgroundColor};
  align-items: center;
`;

export const Logo = styled.img`
  height: 40px;
  padding-left: 20px;
`;

export const LogoMobile = styled.img`
  height: 45px;
`;

export const SidebarButtonContainer = styled.div`
  display: flex;
  outline: 0px auto !important;
  color: #fff;
`;

export const SidebarButton = styled.button`
  outline: 0px auto !important;
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 5px 0px 15px;
  background: transparent;
  border: 0px;
  margin-top: 2px;
`;
