import React from "react";
import Home from "../../components/user/home";
import Layout from "../../components/layouts/simpleLayout";

const HomePage = () => {
  return (
    <Layout
      showBanner
      bannerName="arbol.jpg"
      textBanner="unilevelTree"
      bannerPosition="46"
      body={
        <>
          <Home />
        </>
      }
    />
  );
};

export default HomePage;
