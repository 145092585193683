import { useEffect } from "react";

const AzureSuccessLoginContainer = ({ token }) => {
  useEffect(() => {
    setTimeout(() => {
      window.opener.authenticateCallback(token);
      window.close();
    }, 1000);
  }, [token]);

  return (
    <>
      <div>Authenticated successfully! you are being redirected...</div>
    </>
  );
};

export default AzureSuccessLoginContainer;
