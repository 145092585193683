// import { useNavigate } from "react-router";
// import PATHS from "../../constants/paths";
const HomeContainer = () => {
  // const navigate = useNavigate();

  return (
    <div>
      <div>Home</div>
    </div>
  );
};

export default HomeContainer;
