import React, { Component } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import ChangePasswordForm from "./changePassword.view";
import { useTranslation } from "react-i18next";
import * as UserService from "../../../../services/UserServices";
// import { getUserPersonalInfoSelector } from "../../../store/selectors/authSelector";
import ShowDialog from "../../../utils/ShowDialog/ShowDialog";

const ChangePasswordContainer = () => {
  const { t } = useTranslation();

  const handleSubmit = async (values, resetForm) => {
    try {
      const result = await UserService.changePassword(values);
      let title = "";
      let text = "";
      let icon = "";
      if (result && result === "OK") {
        title = t("forms.security.messages.changePass.title");
        text = t("forms.security.messages.changePass.success");
        icon = "success";
      } else {
        title = "Error";
        text = t("forms.security.messages.changePass.error");
        icon = "error";
      }
      // let okAction = undefined;
      resetForm();
      ShowDialog(title, text, icon, false, "OK");
    } catch (error) {
      //console.log('Error', error);
    }
  };

  return <ChangePasswordForm initialValues={{}} onSubmit={handleSubmit} />;
};

ChangePasswordContainer.propTypes = {};

export default ChangePasswordContainer;
