import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getDataTokenSelector,
  getAuthUserSelector,
} from "../../../../store/selectors/authSelector";
import Languages from "./components/languages";
import {
  HeaderContainer,
  SidebarButtonContainer,
  SidebarButton,
  Logo,
} from "./styled";
import Menu from "./components/menu";
// import ModalForm from "../../../utils/modalForm";

const Header = (props) => {
  const { t } = useTranslation();
  const token = useSelector(getDataTokenSelector);
  const user = useSelector(getAuthUserSelector);

  return (
    <>
      {/* <LoadingModal /> */}
      {/* <ModalForm /> */}
      {/*  <ModalInfo></ModalInfo> */}
      <HeaderContainer className={`row justify-content-between`}>
        <div className="col-2 col-md-6 col-lg-4 align-self-center ">
          <Logo
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_ama.png`}
            alt="logo"
            className="logo d-none d-lg-block"
          />
        </div>

        <div className={`col-8`}>
          <div className="row">
            <div className="col-12 d-flex justify-content-end align-items-center">
              {/* {user && user.email && (
                <UserContainerName>
                  <UserIcon className="fa-solid fa-circle-user"></UserIcon>
                  {user.email}
                </UserContainerName>
              )} */}
              <Languages />
              {token ? (
                <>
                  <SidebarButtonContainer className="d-block d-lg-none">
                    <SidebarButton
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton>
                  </SidebarButtonContainer>
                  <Menu />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </HeaderContainer>
      {/* <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Flip}
        /> */}
    </>
  );
};

Header.propTypes = {
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
};

export default Header;
