import { store } from "../store/index";
import { setFromPlatform, cleanNav } from "../store/actions/NavActions";

export const setExtPlatform = async (from) => {
  await store.dispatch(setFromPlatform(from));
};

export const cleanNavigation = async () => {
  await store.dispatch(cleanNav());
};
