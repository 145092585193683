import { handleActions } from "redux-actions";

import { SET_EXT, CLEAN_NAV } from "../../constants/index";

export const nav = handleActions(
  {
    [CLEAN_NAV]: () => ({}),
    [SET_EXT]: (state, action) => ({
      ...state,
      fromPlatform: action.payload,
    }),
  },
  {}
);
