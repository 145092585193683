import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContainer } from "./components/security/auth/auth.container";
import "./utils/i18n/i18n";

import { AbilityContext } from "./components/security/permissions/Can";
import { buildAbilityFor } from "./components/security/permissions/ability";
import { getTheme } from "./design/themes/utilTheme";
import { ThemeProvider } from "styled-components";

const ability = buildAbilityFor();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <AuthContainer.Provider>
          <ThemeProvider theme={getTheme(process.env.REACT_APP_THEME)}>
            <AbilityContext.Provider value={ability}>
              <App />
            </AbilityContext.Provider>
          </ThemeProvider>
        </AuthContainer.Provider>
      </Router>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
