import { useEffect } from "react";
import LoginForm from "./login.view";
import { AuthContainer } from "../security/auth/auth.container";
import { useNavigate } from "react-router";
import PATHS from "../../constants/paths";
import useQuery from "../utils/hook/useQuery";
import { useSelector } from "react-redux";
import * as NavServices from "../../services/NavServices";
import { getFromPlatformSelector } from "../../store/selectors/navSelector";
import { PLATFORM_REDIRECT } from "../../constants/index";

const LoginContainer = () => {
  const navigate = useNavigate();
  const fromPlatform = useSelector(getFromPlatformSelector);
  const auth = AuthContainer.useContainer();
  let query = useQuery();

  useEffect(() => {
    if (query.get("ext")) {
      NavServices.setExtPlatform(query.get("ext"));
    }
  }, [query]);

  const handleSubmit = async (values) => {
    const result = await auth.login(
      values.user.toLocaleLowerCase(),
      values.password
    );
    if (result) {
      if (fromPlatform) {
        NavServices.cleanNavigation();
        window.location.href = `${
          PLATFORM_REDIRECT[fromPlatform]
            ? PLATFORM_REDIRECT[fromPlatform]
            : PLATFORM_REDIRECT["defaultRedirect"]
        }/${result.externalData.token}`;
      } else {
        navigate(PATHS.SETTING_PROFILE);
      }
    }
  };

  const handleLoginAzure = async (values) => {
    // await UserServices.profile1();
    openCenteredPopup(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/v1/employee/auth/azure`,
      `login with azureAd`,
      400,
      400
    );
    // await UserServices.loginAzure();
    // const result = await auth.login(values.user, values.password);
    // if (result && result === "OK") {
    //   navigate(PATHS.HOME);
    // }
  };

  // const logout = () => {
  //   auth.logout();
  //   openCenteredPopup(
  //     `https://login.microsoftonline.com/99dbb24d-723f-44f0-b082-24b8ce4784d4/oauth2/logout`,
  //     `logout azureAd`,
  //     250,
  //     250
  //   );
  // };
  return (
    <LoginForm
      onSubmit={handleSubmit}
      loginAzure={handleLoginAzure}
      // logout={logout}
    ></LoginForm>
  );
};

export default LoginContainer;

export const openCenteredPopup = (url, title, w, h) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=no,
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      resizable=no,
      copyhistory=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  newWindow?.focus();
};
