import { createSelector } from "reselect";

const getStateNavSelector = (state) => state.nav;

export const getLanguageSelector = createSelector(
  getStateNavSelector,
  (nav) => {
    if (nav && nav.language) {
      return nav.language;
    }

    return undefined;
  }
);

export const getFromPlatformSelector = createSelector(
  getStateNavSelector,
  (nav) => {
    if (nav && nav.fromPlatform) {
      return nav.fromPlatform;
    }

    return undefined;
  }
);
