// AuthContainer.ts

import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { useNavigate } from "react-router";
import { useToken } from "../token/useToken";
import * as UserServices from "../../../services/UserServices";
import * as NavServices from "../../../services/NavServices";
import * as GeneralServices from "../../../services/GeneralServices";
import { useSelector } from "react-redux";
import { getFromPlatformSelector } from "../../../store/selectors/navSelector";
import { PLATFORM_REDIRECT } from "../../../constants/index";
import PATHS from "../../../constants/paths";

function useAuth() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  // const refreshToken = useCallback(refresh, []);
  const onTokenInvalid = () => setUser(null);
  const fromPlatform = useSelector(getFromPlatformSelector);

  const { clearToken, isAuthenticated } = useToken(
    onTokenInvalid
    // refreshToken
  );

  // useEffect(() => {
  //   // try to get new token on first render using refresh token
  //   refreshToken();
  // }, [refreshToken]);

  useEffect(() => {
    // add listener for login or logout from other tabs
    window.addEventListener("storage", async (event) => {
      if (event.key === "SSO_LOGOUT" && isAuthenticated()) {
        await clearToken(false);
      } else if (event.key === "SSO_LOGIN") {
        // refreshToken();
      }
    });

    window.authenticateCallback = async function (token) {
      window.localStorage.setItem("SSO_LOGIN", new Date().toISOString());
      if (fromPlatform) {
        NavServices.cleanNavigation();
        window.location.href = `${
          PLATFORM_REDIRECT[fromPlatform]
            ? PLATFORM_REDIRECT[fromPlatform]
            : PLATFORM_REDIRECT["defaultRedirect"]
        }/${token}`;
      } else {
        await GeneralServices.getItemLoginTemp(token);
        navigate(PATHS.SETTING_PROFILE);
      }
      // await refreshToken();
    };
  }, [clearToken, isAuthenticated, navigate]);

  const logout = useCallback(() => {
    clearToken().finally(() => {
      navigate("/");
      // fire an event to logout from all tabs
      window.localStorage.setItem("SSO_LOGOUT", new Date().toISOString());
    });
  }, [navigate, clearToken]);

  const login = useCallback(async (email, password) => {
    try {
      const data = await UserServices.login(email, password);
      if (data && data.user) {
        // fire an event to let all tabs know they should login
        window.localStorage.setItem("SSO_LOGIN", new Date().toISOString());
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }, []);

  // async function refresh() {
  //   try {
  //     const data = await UserServices.refreshToken();
  //     if (data && data.access_token) {
  //     } else {
  //       logout();
  //     }
  //   } catch (error) {
  //     logout();
  //   }
  // }

  return {
    user,
    setUser,
    login,
    logout,
    // refreshToken,
  };
}

export const AuthContainer = createContainer(useAuth);
