import styled from "styled-components";

export const BodyContainer = styled.div`
  background-color: #fdfdfd;
  min-height: 100vh;
  color: #000;
`;

export const ButtonSSO = styled.button`
  background-color: #103830;
  width: 100%;
  padding: 20px;

  &:hover {
    background-color: #103830 !important;
    border-color: #fff !important;
  }
`;

export const ImgSSO = styled.img`
  width: 150px;
`;

export const TextSSO = styled.div`
  color: #fff;
  font-size: 1rem;
  margin-bottom: 20px;
`;

export const ButtonNormal = styled.button`
  width: 100%;
`;
