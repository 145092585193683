// import { useNavigate } from "react-router";
import ChangePassword from "./changePassword";

const SecurityContainer = () => {
  // const navigate = useNavigate();

  return (
    <div className="row">
      <div className="col-12">
        <ChangePassword />
      </div>
    </div>
  );
};

export default SecurityContainer;
