import React from "react";
import UserProfile from "../../../../components/user/profile";
import Layout from "../../../../components/layouts/settingsLayout";

const UserSettingsPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      active="profile"
      body={
        <>
          <UserProfile />
        </>
      }
    />
  );
};

UserSettingsPage.propTypes = {};

export default UserSettingsPage;
