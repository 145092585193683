import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { dataModel, validationDataModel } from "./login.data.form";
import { useTranslation } from "react-i18next";
import InputText from "../utils/InputText";
import { ButtonSSO, ImgSSO, TextSSO, ButtonNormal } from "./styled";
import { useState } from "react";

const LoginForm = (props) => {
  const { handleSubmit, loginAzure, logout } = props;
  const { t } = useTranslation();
  const [normalLogin, setNormalLogin] = useState(false);

  const changeMode = () => {
    setNormalLogin(!normalLogin);
  };
  return (
    <div className="container-fluid">
      <div className="login-page row justify-content-center align-items-center no-margin">
        <div className="col-12 col-md-6 col-lg-3">
          {!normalLogin ? (
            <>
              <div className="row">
                <div className="col-12">
                  <ButtonSSO
                    type="button"
                    className="btn btn-lg btn-primary btn-block"
                    onClick={() => loginAzure()}
                  >
                    <TextSSO>{t("buttonSso")}</TextSSO>
                    <ImgSSO
                      src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_ama.png`}
                    />
                  </ButtonSSO>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <ButtonNormal
                    type="button"
                    className="btn btn-lg btn-primary btn-block"
                    onClick={() => changeMode()}
                  >
                    <span>{t("buttonNormalLogin")}</span>
                  </ButtonNormal>
                </div>
              </div>
            </>
          ) : (
            <>
              <form
                className="form-horizontal bordered-row"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="user"
                      className="form-control"
                      name="user"
                      component={InputText}
                      fieldType="text"
                      labelField="Usuario"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Field
                      id="password"
                      className="form-control"
                      name="password"
                      component={InputText}
                      fieldType="password"
                      showHidePassword
                      labelField="Contraseña"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary btn-block"
                    >
                      Acceder
                    </button>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12">
                    <ButtonNormal
                      type="button"
                      className="btn btn-md btn-primary btn-block"
                      onClick={() => changeMode()}
                    >
                      <span>{t("buttonSso2")}</span>
                    </ButtonNormal>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  loginAzure: PropTypes.func,
};

const LoginFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: "LoginForm",
})(LoginForm);

export default LoginFormF;
