import { useSelector } from "react-redux";
import { getAuthUserSelector } from "../../../store/selectors/authSelector";
import { useTranslation } from "react-i18next";
import { Container } from "./profile.styled";

const HomeContainer = () => {
  // const navigate = useNavigate();
  const user = useSelector(getAuthUserSelector);
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12 mb-4">
            <h3>
              <ins>{t("forms.profile.title")}</ins>
            </h3>
          </div>
        </div>
        <div className="row">
          <Container className="col-12 mb-4">
            <div className="row">
              <div className="col-12 mb-3">
                <label className="control-label mb-1">
                  {t("forms.profile.labels.code")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={`${user.code}`}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="control-label mb-1">
                  {t("forms.profile.labels.name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={`${user.name} ${user.surname1} ${user.surname2}`}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="control-label mb-1">
                  {t("forms.profile.labels.email")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={user.email}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="control-label mb-1">
                  {t("forms.profile.labels.nif")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={user.nif}
                  readOnly
                />
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
