// import PATHS from "../../constants/paths";
// import { createBrowserHistory } from "history";
import ShowDialog from "../../components/utils/ShowDialog/ShowDialog";
import i18next from "i18next";

const getErrorMessage = (code) => {
  const dataError = code.split("##");
  let translate = "";

  if (dataError.length > 1) {
    const params = {};
    for (let index = 1; index < dataError.length; index++) {
      const dat = dataError[index];
      const objData = dat.split("=");
      params[objData[0]] = objData[1];
    }
    translate = i18next.t(
      `server.errors.${dataError[0].toLowerCase()}`,
      params
    );
  } else {
    translate = i18next.t(`server.errors.${dataError[0].toLowerCase()}`);
  }

  // if (translate === `server.errors.${dataError[0].toLowerCase()}`) {
  //   translate = i18next.t(`server.errors.unknowTranslate`);
  // }
  return translate;
};

const showErrorDialog = (title, codeErrorMessage, titleMessage, detail) => {
  // let text = `<div class="row"><div class="col-12">Error code: ${codeError}</div></div>`;
  let text = "";
  if (codeErrorMessage) {
    text += `<div class="row no-margin"><div class="col-12">${getErrorMessage(
      codeErrorMessage
    )}</div></div>`;
  } else {
    text += `<div class="row no-margin"><div class="col-12">${getErrorMessage(
      titleMessage
    )}</div></div>`;
  }
  if (detail) {
    text += `<div class="row justify-content-center no-margin"><div class="col-12 col-md-8 d-flex align-items-center justify-content-center">${detail}</div></div>`;
  }

  ShowDialog(
    "Error",
    text,
    "error",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    "#00a79d",
    "#272727"
  );
};

const createBodyErrorDetail = (details) => {
  let detail = `<ul class="ulError">`;
  details.forEach((det) => {
    // det.messages.forEach((mens) => {
    detail += `<li>${det.path[0]}</li>`;
    // });
  });
  detail += "</ul>";
  return detail;
};

export const handleErrorResponse = (error) => {
  const { response } = error;
  // if (
  //   response.status === 401 &&
  //   (response.data === "Unauthorized" ||
  //     response.data.error.message !== "USER.LOGIN.EMAIL_OR_PASSWORD_INCORRECT")
  // ) {
  // createBrowserHistory().push(PATHS.LOGIN);
  // window.location.reload();
  if (response.status === 503) {
    // createBrowserHistory().push(PATHS.LOGIN);
    // window.location.reload();
  } else if (
    response.status === 404 &&
    response.data.error.code === "CASHFLOW.NOT_FOUND"
  ) {
  } else {
    if (
      response.data &&
      response.data.error &&
      response.status !== 412 &&
      response.status !== 503
    ) {
      const { error } = response.data;
      if (error.joi) {
        const title = error.error;

        let detail = null;
        if (error.details?.body && error.details?.body.length > 0) {
          detail = createBodyErrorDetail(error.details.body);
        }

        showErrorDialog(title, "joi", "test", detail);
      } else {
        const title = error.error;
        let specialError = false;

        if (error?.code?.date) {
          specialError = true;
        }

        if (!specialError) {
          let codeErrorMessage = "";
          if (error?.code?.code) {
            codeErrorMessage = error.code.code;
          } else {
            codeErrorMessage = error.code;
          }
          const messageServer = error.message;
          let detail = null;
          if (error.details && error.details.length > 0) {
            detail = createBodyErrorDetail(error.details);
          }

          showErrorDialog(title, codeErrorMessage, messageServer, detail);
        }
      }
    }
  }
};
