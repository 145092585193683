import { Navigate, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import SuccessAzure from "./pages/successAzure";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import ProfileSettingsPage from "./pages/user/settings/profile";
import SecuritySettingsPage from "./pages/user/settings/security";

function App() {
  // const navigate = useNavigate();
  // const { user } = AuthContainer.useContainer();

  return (
    <Routes>
      {/* <Route path="/logout" element={<Logout />} /> */}
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/home" exact element={<HomePage />} />
        <Route
          path={PATHS.SETTING_PROFILE}
          exact
          element={<ProfileSettingsPage />}
        />
        <Route
          path={PATHS.SETTING_SECURITY}
          exact
          element={<SecuritySettingsPage />}
        />
      </Route>

      <Route
        path="/authentication/redirect/:token"
        exact
        element={<SuccessAzure />}
      />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/" element={() => <Navigate to={"/login"} />} />
    </Routes>
  );
}

export default App;
