const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;

/** AUTH **/
export const urlAuthLogin = `${urlBase}v1/employee/login`;
export const urlAuthLoginAzure = `${urlBase}v1/employee/auth/azure`;
export const urlRefreshToken = `${urlBase}v1/employee/refresh-token`;
export const urlLogout = `${urlBase}v1/employee/logout`;

export const urlAuthChangePass = `${urlBase}v1/employee-s/changePassword`;
export const urlGetLoginItemTemp = `${urlBase}v1/items/login`;
